import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSheet,{staticClass:"mx-n4",attrs:{"height":"20","color":"Greyscale_002"}}),_c(VCardTitle,{staticClass:"d-flex justify-center align-center"},[_c('strong',[_vm._v("駐車場の空き状況")])]),_c('Capacity'),_c(VCardText,[_vm._v(" 駐車場予約エリアの空き状況を表示しています。"),_c('br'),_vm._v(" ○：予約できます＝１日を通して全ての時間帯で空きスペースあり"),_c('br'),_vm._v(" △：時間帯によって予約可能。１日を通しての予約はできません＝空きスペースのある時間とない時間帯が混在"),_c('br'),_vm._v(" ×予約できません＝1日を通してすべての時間帯で空きスペースなし ")]),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c('router-link',{attrs:{"to":"/auth/guest-login"}},[_c(VBtn,{staticClass:"ma-5",attrs:{"color":"Main_0003","width":"300"}},[_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v("予約へ進む")])])])],1)],1),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c('router-link',{attrs:{"to":"/auth/guest-login"}},[_c(VBtn,{staticClass:"ma-5",attrs:{"outlined":"","color":"Main_0003","width":"300"}},[_c('span',{staticClass:"Main_0003--text"},[_c('strong',[_vm._v("初めての方はこちら")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }