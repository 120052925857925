<template>
  <div>
    <v-sheet height="20" color="Greyscale_002" class="mx-n4"></v-sheet>
    <v-card-title class="d-flex justify-center align-center">
      <strong>駐車場の空き状況</strong>
    </v-card-title>
    <Capacity></Capacity>
    <v-card-text>
      駐車場予約エリアの空き状況を表示しています。<br>
      ○：予約できます＝１日を通して全ての時間帯で空きスペースあり<br>
      △：時間帯によって予約可能。１日を通しての予約はできません＝空きスペースのある時間とない時間帯が混在<br>
      ×予約できません＝1日を通してすべての時間帯で空きスペースなし
    </v-card-text>
    <v-row align="center" justify="center">
      <router-link to="/auth/guest-login">
        <v-btn color="Main_0003" class="ma-5" width="300">
          <span class="white--text">
            <strong>予約へ進む</strong>
          </span>
        </v-btn>
      </router-link>
    </v-row>
    <v-row align="center" justify="center">
      <router-link to="/auth/guest-login">
        <v-btn outlined color="Main_0003" class="ma-5" width="300">
          <span class="Main_0003--text">
            <strong>初めての方はこちら</strong>
          </span>
        </v-btn>
      </router-link>
    </v-row>
  </div>
</template>

<script>
import Capacity from "@/resources/components/guest/capacity.component.vue";
export default {
  components: {
      Capacity,
  },
}
</script>
